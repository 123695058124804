<template>
  <div v-if="show" class="fixed w-full h-full z-10 top-0 left-0">
    <div class="bg-gray-900 opacity-50 fixed w-full h-full" @click="next"></div>
    <b-button size="is-small"
        icon-left="close" type="rounded" rounded style="top: 1rem; right: 1rem; padding: 1rem; position: fixed; width: 2.5em; height: 2.5em" @click="close">
    </b-button>
    <img v-if="!mounting" :src="currentScene.img" :style="imgStyle" class="z-10 fixed" @click="next" />
  </div>
</template>

<script>
export default {
  props: {
    platform: {
      type: String,
      default: 'web'
    },
    scenes: Array,
    storageKey: String,
    anchor: String
  },
  data () {
    return {
      index: -1,
      imgStyle: { top: 0, left: 0, width: 0 },
      mounting: true
    }
  },
  mounted () {
    const hasViewTour = localStorage.getItem(this.storageKey)
    if (!hasViewTour) this.start()
  },
  methods: {
    start () {
      if (!this.$route.path.includes('dashboard')) this.$router.push('/')
      this.next()
      window.addEventListener('resize', this.positionScene)
      window.addEventListener('scroll', this.positionScene)
    },
    async next () {
      await this.resetPreviousElement()

      if (this.index + 1 >= this.scenes.length) {
        this.close()
        return
      }

      this.mounting = true
      this.index++

      await this.positionScene()

      this.mounting = false
    },
    async close () {
      await this.resetPreviousElement()
      this.index = -1
      this.mounting = false
      this.removeListeners()
      localStorage.setItem(this.storageKey, 1)
    },
    async resetPreviousElement () {
      if (this.index >= 0) {
        const previousElement = await this.getElement(this.currentScene.elementId)
        previousElement.style.zIndex = 0
        previousElement.style.pointerEvents = 'initial'
      }
    },
    async positionScene () {
      const element = await this.getElement(this.currentScene.elementId)
      const elementRect = element.getBoundingClientRect()

      this.imgStyle = this.calcImagePosition(elementRect)
      element.style.zIndex = 9999
      element.style.pointerEvents = 'none'
    },
    calcImagePosition (elementRect) {
      const scale = this.currentScene.scale || 1
      const { height, width } = elementRect
      const {
        anchor,
        top: sceneTop = '0px',
        left: sceneLeft = '0px'
      } = this.currentScene

      const scaledWidth = scale * width
      const scaledHeight = scale * height
      const mobileWidth = 'calc(100% - 2rem)'

      if (anchor === 'top' || anchor === 'bottom') {
        const { top, bottom } = elementRect
        const left = scale > 1 ? (elementRect.left + (width / 2)) - (scaledWidth / 2) : elementRect.left

        const styles = {
          left: this.isMobile ? '1rem' : `calc(${left}px + ${sceneLeft})`,
          width: this.isMobile ? mobileWidth : scaledWidth + 'px'
        }

        if (this.isMobile) styles.right = left

        if (anchor === 'top') {
          styles.bottom = window.innerHeight - top + 'px'
        } else {
          styles.top = bottom + 'px'
        }

        return styles
      }

      if (anchor === 'left' || anchor === 'right') {
        const { left, right } = elementRect
        const top = scale > 1 ? (elementRect.top + (height / 2)) - (scaledHeight / 2) : elementRect.top
        const styles = {
          top: `calc(${top}px + ${sceneTop})`,
          height: scaledHeight + 'px'
        }
        if (anchor === 'left') {
          styles.right = `${window.innerWidth - left}px`
        } else {
          styles.left = right + 'px'
        }
        return styles
      }
    },
    getElement (id) {
      const tryGetElement = (resolve) => {
        const element = document.getElementById(id)
        if (!element) {
          setTimeout(() => {
            tryGetElement(resolve)
          }, [300])
        } else {
          resolve(element)
        }
      }
      return new Promise(tryGetElement)
    },
    removeListeners () {
      window.removeEventListener('resize', this.positionScene)
      window.removeEventListener('scroll', this.positionScene)
    }
  },
  computed: {
    currentScene () {
      return this.scenes[this.index]
    },
    show () {
      return this.index >= 0
    }
  },
  beforeDestroy () {
    this.removeListeners()
  }
}
</script>

<style>

</style>
